<template>
  <div>  
       <b-row class="justify-content-center">
        <b-col cols="8" md="4" :style="isMobile ? 'margin-top: -30px;' : 'margin-top: -80px;'" v-if="isMobile">
            <center>
                <img src="elementos/movi.png" class="rounded" alt="Image" :width="isMobile ? 90 : ''">
                <img src="elementos/logos.png" class="rounded" alt="Image" :width="isMobile ? 220 : ''">
            </center>
        </b-col>
         <b-col cols="6" md="4" :style="isMobile ? '' : 'margin-top: -80px;'" v-if="!isMobile">
             <img src="elementos/logos.png" class="rounded" alt="Image" :width="isMobile ? 220 : ''">
         </b-col>
         <b-col cols="4" md="6" v-if="!isMobile"></b-col>
         <b-col cols="6" md="2" :style="isMobile ? 'margin-left:20px;' : 'margin-top: -80px;'" v-if="!isMobile">
              <img src="elementos/movi.png" class="rounded" alt="Image" :width="isMobile ? 90 : ''">
         </b-col>
         <b-col cols="11" md="12">
            <center>
                <br> <br> <br> <br> <br> 
                <h2 class="text-white">Encuesta de satisfacción</h2>
                <img src="encuesta/convencionb.png" class="rounded" alt="Image" :width="isMobile ? 320 : 600" >
                <br>
                <b-button  type="button" variant="primary" class="mt-5" 
                :style="isMobile ? 'background: #ff5c36;  border-color: #ff5c36; width: 40%;  border-radius: 15px;' : 'background: #ff5c36;  border-color: #ff5c36; width: 15%;  border-radius: 15px;'" @click="descargaReporte()">
                    Descargar <b-spinner v-if="loader" small></b-spinner> 
                </b-button>
            </center>
         </b-col>  
       </b-row>     
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
// import $ from "jquery";
// import InitController from "@/utils/InitController";  
// import Swal from 'sweetalert2'
// Import Bootstrap and BootstrapVue CSS files (order is important) 
export default {
  name: 'encuesta-admin',
  //  components: { Multiselect },
  data(){
    return{
      isMobile: this.$mobile(), 
      loader:false
    }
  }, 
  methods:{
    descargaReporte(){
        this.loader = true;
          this.$fileDownload(
            this.$api.baseUri + 'auth/reporteconvencion',  "reporte-encuesta-convencion.xlsx",
            ).then(
                () => {
                    this.loader = false; 
                }
            ) 
    }
  },
  mounted(){
    //  window.location.href = "https://convencioncomercial.com.mx/encuesta/encuesta.jpg";   
   if (this.isMobile)
    {
      this.$bus.$emit("cambi_fo2", true)
    } 
  }
}
</script>  
<style>
 .form-check-input[type=radio] {
    width: 1.5rem;
    height: 1.5rem;
    border-color: black;
 }
</style>